import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {findIndex} from "lodash";

export default {
  name: 'ingredients',
  data() {
    return {
      alphabets: [],
      currentLetters: [],
      isLockLetters: false,
      currentLanguage: 'en',
      test: [],
    }
  },
  components: {
    emptyData
  },
  watch: {
    '$route.name'() {
      console.log('clear');
      this.test = []
    }
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  created() {
    switch (this.$route.params.lang) {
      case 'ua':
        this.alphabets.push({
          lang: 'ua',
          list: 'АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'
        })
        break;
      case 'ru':
        this.alphabets.push({
          lang: 'ru',
          list: 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'
        })
        break;
      case 'en':
        this.alphabets.push({
          lang: 'en',
          list: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        })
        break;
    }
    this.test = this.ingredientsList

  },
  mounted() {
    this.test = []
  },
  computed: {
    ...mapGetters({
      ingredientsList: 'ingredients/ingredientsList',
      isLoadingIngredients: 'ingredients/isLoadingIngredients',
    }),
  },
  methods: {
    ...mapActions({
      fetchSearchList: `ingredients/INGREDIENTS_SEARCH_GET`,
    }),
    getCurrentIndex(letter) {
      return findIndex(this.currentLetters, item => {
        return item === letter;
      });
    },
    toggleLetter(letter, lang) {
      this.isLockLetters = true;
      if (this.currentLanguage === lang) {
        let index = this.getCurrentIndex(letter)
        if (index >= 0) {
          this.currentLetters.splice(index, 1);
        } else {
          this.currentLetters.push(letter)
        }


      } else {
        this.currentLanguage = lang
        let index = this.getCurrentIndex(letter)
        if (index >= 0) {
          this.currentLetters.splice(index, 1);
        } else {
          this.currentLetters.push(letter)
        }


      }
      this.fetchSearchList({letters: this.currentLetters, locale: this.currentLanguage}).finally(() => {
        this.isLockLetters = false;
        this.test = []
        this.test = this.ingredientsList

        // let sortedUsers = [].slice.call(arguments).sort(function(a, b) {
        //   // ignore upper and lowercase
        //   if (a < b) {
        //     return -1; //nameA comes first
        //   }
        //   if (a > b) {
        //     return 1; // nameB comes first
        //   }
        //   return 0;  // names must be equal
        // });
        // this.test = sortedUsers
        console.log(this.test, 'sorted')
        // console.log(sortedUsers)


        // this.test = this.ingredientsList
        // console.log(this.ingredientsList, 'sortable');
      })
    }
  }
}
